<template>
    <!-- BaseMenuActions.vue -->
    <v-menu left offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="disabled"
                icon
            >
                <v-icon :color="iconColor">{{ icon }}</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item
                v-for="item in items"
                :key="item.title"
                @click="$emit(item.event, item)"
            >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <v-list-item-action>
                    <v-icon :color="item.color">{{ item.icon }}</v-icon>
                </v-list-item-action>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    export default {
        name: 'BaseMenuActions',
        props: {
            /**
             * Disable the menu
             */
            disabled: {
                type: Boolean,
                default: false
            },
            /**
             * The activator icon
             */
            icon: {
                type: String,
                default: 'mdi-cog'
            },
            /**
             * The icon color
             */
            iconColor: {
                type: String,
                default: 'primary'
            },
            /**
             * The menu items [{title: string, icon: string, color: string, event: string}]
             */
            items: {
                type: Array,
                default: () => []
            }
        }
    }
</script>
