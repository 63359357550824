<template>
    <!-- InvitationsTable.vue -->
    <v-card class="mx-auto" max-width="900">
        <!-- Toolbar -->
        <base-table-toolbar
            :title="title"
        >
            <template v-slot:actions>
                <!-- Search Input -->
                <v-text-field
                    v-model="search"
                    flat
                    hide-details
                    label="Search..."
                    solo
                    @input="handleSearchInput"
                ></v-text-field>
                <v-btn :disabled="loading" color="success" icon @click="handleClickCreate">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </template>
        </base-table-toolbar>
        <v-divider></v-divider>
        <!-- Table -->
        <v-data-table
            :headers="headers"
            :item-key="itemKey"
            :items="items"
            hide-default-footer
            :disable-pagination="true"
            :items-per-page="itemsPerPage"
            :loading="loading"
            :value="selectedItems"
            @input="handleTableInput"
        >
            <!-- Guests -->
            <template v-slot:item.guests="{item}">
                {{ item.guests.length }}
            </template>

            <!-- Confirmed At -->
            <template v-slot:item.confirmed_at="{item}">
                <div v-if="!item.confirmed_at">N/A</div>
                <base-date-tooltip v-else :value="item.confirmed_at"></base-date-tooltip>
            </template>

            <!-- Actions -->
            <template v-slot:item.actions="{item}">
                <v-row justify="end">
                    <v-btn icon class="mr-1" color="info" :href="`/invitation/${item.id}`" target="_blank">
                        <v-icon>mdi-email</v-icon>
                    </v-btn>
                    <v-btn :disabled="loading" class="mr-1" color="success" icon @click="handleClickUpdate(item)">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn :disabled="loading" color="error" icon @click="handleClickRemove(item)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </v-row>
            </template>
        </v-data-table>
    </v-card>
</template>

<style>
    .mobile-toolbar > .v-toolbar__content {
        padding-left: 0;
    }
</style>

<script>
    import BaseDateTooltip from '@/components/BaseDateTooltip'
    import BaseTableMixin from '@/mixins/base-table.mixin'
    import BaseTableToolbar from '@/components/BaseTableToolbar'

    export default {
        name: 'InvitationsTable',
        mixins: [BaseTableMixin],
        data: () => ({search: ''}),
        props: {
            /**
             * Total items to display per page
             */
            itemsPerPage: {
                type: Number,
                default: 100
            },
            /**
             * The max width of the table
             */
            maxWidth: {
                type: Number,
                default: 800
            },
            /**
             * The title of the table
             */
            title: {
                type: String,
                default: 'Guests'
            }
        },
        components: {
            BaseDateTooltip,
            BaseTableToolbar
        },
        computed: {
            headers () {
                return [
                    {value: 'name', text: 'Name'},
                    {value: 'side', text: 'Side'},
                    {value: 'guests', text: 'Guests'},
                    {value: 'attending', text: 'Attending'},
                    {value: 'confirmed_at', text: 'Confirmed'},
                    {value: 'actions', text: 'Actions', sortable: false, align: 'right'}
                ]
            }
        },
        methods: {
            handleClickCreate () {
                /**
                 * @event click:create
                 */
                this.$emit('click:create')
            },
            handleClickUpdate (item) {
                /**
                 * @event click:update
                 * @type {object}
                 */
                this.$emit('click:update', item)
            },
            handleClickRemove (item) {
                /**
                 * @event click:remove
                 * @type {object}
                 */
                this.$emit('click:remove', item)
            },
            handleSearchInput (val) {
                /**
                 * @event search
                 * @type {string}
                 */
                this.$emit('search', val)
            }
        }
    }
</script>
