<template>
    <!-- Admin.vue -->
    <v-container fluid>
        <v-row>
            <v-col cols="12" class="text-center">
                <v-btn-toggle v-model="filters.side" color="info">
                    <v-btn
                        small
                        :value="side.value"
                        v-for="side in guestSides"
                        :key="side.value"
                    >
                        {{ side.text }}
                    </v-btn>
                </v-btn-toggle>
            </v-col>
            <v-col cols="12">
                <v-row align="center" justify="center" no-gutters>
                    <v-card
                        :dark="filters.attending === stat.filter"
                        :color="filters.attending === stat.filter ? 'primary' : ''"
                        v-for="stat in stats"
                        @click="handleAttending(stat.filter)"
                        :key="stat.name"
                        class="ma-3"
                        width="100"
                    >
                        <v-card-title class="justify-center">{{ stat.value }}</v-card-title>
                        <v-card-subtitle class="text-center">{{ stat.name }}</v-card-subtitle>
                    </v-card>
                </v-row>
            </v-col>
            <v-col cols="12">
                <!-- Invitations Table -->
                <invitations-table
                    :items="invitationsFiltered"
                    :loading="loading"
                    @search="handleSearch"
                    @click:create="handleCreate"
                    @click:update="handleUpdate"
                    @click:remove="confirmRemove"
                ></invitations-table>
            </v-col>
            <v-col cols="12">
                <v-dialog :max-width="!remove ? '100vw' : 500" :value="Boolean(selectedInvitation) || create"
                          persistent>
                    <invitation-form
                        v-if="create || update"
                        :item="selectedInvitation"
                        @close="handleClose"
                        @submit="handleSubmit"
                    ></invitation-form>
                    <v-card v-else-if="remove">
                        <v-toolbar color="secondary" dark elevation="1" flat>
                            <v-toolbar-title>Remove Invitation</v-toolbar-title>
                        </v-toolbar>
                        <v-container fluid pb-0 px-0>
                            <v-row class="no-gutters">
                                <!-- Form -->
                                <v-col cols="12">
                                    <v-card-title class="error--text justify-center">
                                        <div class="text-body-1">
                                            Are you sure you want to remove this invitation?
                                        </div>
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-actions class="ma-3">
                                        <v-spacer></v-spacer>
                                        <v-btn class="mr-3" @click="handleClose">
                                            <v-icon class="mr-1">mdi-close</v-icon>
                                            Close
                                        </v-btn>
                                        <v-btn color="error" @click="handleRemove">
                                            <v-icon class="mr-1">mdi-delete</v-icon>
                                            Remove
                                        </v-btn>
                                    </v-card-actions>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import logger from '@/utils/logger'
    import guestSides from '@/store/static/guest-sides'
    // Mixins
    import BaseCardWidthMixin from '@/mixins/base-card-width.mixin'
    // Components
    import InvitationsTable from '@/components/InvitationsTable'
    import InvitationForm from '@/components/InvitationForm'
    import {findWhere, flatten, map, pluck, isBoolean} from 'underscore'

    export default {
        name: 'Admin',
        mixins: [BaseCardWidthMixin],
        data: () => ({
            guestSides,
            filters: {
                side: null,
                attending: undefined
            },
            selected: null,
            search: '',
            loading: false,
            create: false,
            update: false,
            remove: false
        }),
        components: {
            InvitationsTable,
            InvitationForm
        },
        computed: {
            ...mapGetters('invitations', ['invitations']),
            capitalise () {
                return name => `${name.charAt(0).toUpperCase()}${name.slice(1)}`
            },
            invitationsFormatted () {
                const vm = this

                return map(vm.invitations, invitation => {
                    const guests = invitation.guests
                    const attending = pluck(guests, 'attending')?.reduce((a, b) => a + b, 0) || 0

                    return {...invitation, attending}
                })
            },
            invitationsFiltered () {
                const vm = this
                let invitations = vm.invitationsFormatted

                if (vm.filters.side) {
                    invitations = invitations.filter(invitation => {
                        return invitation.side === vm.filters.side
                    })
                }

                if (isBoolean(vm.filters.attending)) {
                    invitations = invitations.filter(invitation => {
                        if (!invitation.confirmed_at) {
                            return false
                        }

                        return vm.filters.attending
                            ? invitation.attending > 0
                            : invitation.attending < invitation.guests.length
                    })
                }

                if (vm.hasSearch) {
                    const search = vm.search.toString().toLowerCase()
                    const isMatch = string => string.indexOf(search) !== -1

                    invitations = invitations.filter(invitation =>
                        isMatch(invitation.name.toLowerCase()) ||
                        isMatch(invitation.side.toLowerCase())
                    )
                }

                return invitations
            },
            hasSearch () {
                return Boolean(this.search)
            },
            selectedInvitation () {
                const vm = this

                return findWhere(vm.invitations, {id: vm.selected?.id})
            },
            stats () {
                const vm = this
                const invitations = vm.invitationsFiltered
                const guests = flatten(pluck(invitations, 'guests'))?.length || 0
                const attending = pluck(invitations, 'attending')?.reduce((a, b) => a + b, 0)

                const notAttending = map(invitations, invitation => invitation.confirmed_at
                    ? invitation.guests.length - invitation.attending
                    : 0
                ).reduce((a, b) => a + b, 0)

                return [
                    {
                        name: 'Invited',
                        value: guests,
                        filter: undefined
                    },
                    {
                        name: 'Yes',
                        value: attending,
                        filter: true
                    },
                    {
                        name: 'No',
                        value: notAttending,
                        filter: false
                    }
                ]
            }
        },
        methods: {
            ...mapActions('invitations', ['createInvitation', 'listInvitations', 'resetInvitations', 'updateInvitation', 'removeInvitation']),
            confirmRemove (item) {
                const vm = this
                vm.selected = item
                vm.remove = true
            },
            handleClose () {
                const vm = this
                vm.selected = null
                vm.create = false
                vm.update = false
                vm.remove = false
            },
            handleCreate () {
                this.create = true
            },
            async handlePageLoad () {
                const vm = this
                try {
                    vm.loading = true
                    await vm.listInvitations()
                } catch (e) {
                    logger.error(e)
                } finally {
                    vm.loading = false
                }
            },
            handleUpdate (item) {
                const vm = this
                vm.update = true
                vm.selected = item
            },
            async handleRemove () {
                const vm = this

                try {
                    await vm.removeInvitation(vm.selected.id)
                    vm.selected = null
                    vm.remove = false
                } catch (e) {
                    logger.error(e)
                }
            },
            handleSearch (val) {
                this.search = val
            },
            async handleSubmit (data) {
                const vm = this
                let promise = vm.createInvitation

                if (data.id) {
                    promise = vm.updateInvitation
                }

                try {
                    await promise(data)
                    vm.selected = null
                    vm.handleClose()
                } catch (e) {
                    logger.error(e)
                }
            },
            handleAttending (isAttending) {
                const vm = this

                if (vm.filters.attending === isAttending) {
                    vm.filters.attending = undefined
                    return
                }

                vm.filters.attending = isAttending
            }
        },
        created () {
            this.handlePageLoad()
        },
        destroyed () {
            this.resetInvitations()
        }
    }
</script>
