<template>
    <!-- BaseTableToolbar.vue -->
    <v-toolbar :class="{'mobile-toolbar': $vuetify.breakpoint.mobile}" dense elevation="0">
        <!-- Left Actions -->
        <base-menu-actions
            v-if="hasItemActions"
            v-on="itemActionEvents()"
            :items="itemActions"
            icon="mdi-pencil-box-multiple"
        ></base-menu-actions>

        <template v-if="!$vuetify.breakpoint.mobile">
            <!-- Toolbar Title -->
            <v-toolbar-title>
                <span class="primary--text font-weight-medium">{{ title }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
        </template>

        <!-- @slot actions -->
        <slot name="actions"></slot>

        <!-- Right Actions -->
        <base-menu-actions
            v-if="hasMenuActions"
            v-on="menuActionEvents"
            :items="menuActions"
            icon="mdi-dots-vertical"
        ></base-menu-actions>
    </v-toolbar>
</template>

<script>
    import {isEmpty} from 'underscore'
    // Mixins
    import BaseTableEventsMixin from '../mixins/base-table-events-mixin'
    // Components
    import BaseMenuActions from './BaseMenuActions'

    /**
     * Table toolbar component, displays title, handles bulk actions in a menu, and table actions in another.
     */
    export default {
        name: 'BaseTableToolbar',
        mixins: [BaseTableEventsMixin],
        props: {
            /**
             * Array of item (bulk) actions.  If empty icon is hidden
             */
            itemActions: {
                type: Array,
                default: () => []
            },
            /**
             * Array of menu actions.  Displayed on the right of toolbar.  If empty icon is hidden
             */
            menuActions: {
                type: Array,
                default: () => []
            },
            /**
             * The toolbar title
             */
            title: {
                type: String,
                default: null
            }
        },
        components: {
            BaseMenuActions
        },
        computed: {
            hasItemActions () {
                return !isEmpty(this.itemActions)
            },
            hasMenuActions () {
                return !isEmpty(this.menuActions)
            }
        }
    }
</script>
