<template>
    <!-- BaseDateTooltip.vue -->
    <v-tooltip bottom>
        <template v-slot:activator="{on}">
            <span v-on="on">
                <time
                    :data-test="$attrs['data-test'] || 'baseDateTooltip'"
                    :datetime="value"
                >
                    {{ valueFormatted }}
                </time>
            </span>
        </template>
        {{ value }}
    </v-tooltip>
</template>

<script>
    import {formatRelative} from 'date-fns'
    import {enGB} from 'date-fns/locale'

    /**
     * Tooltip component which shows a formatted date, and the raw timestamp in the tooltip.
     */
    export default {
        name: 'BaseDateTooltip',
        props: {
            /**
             * The un-formatted datetime value
             */
            value: {
                type: String,
                required: true
            }
        },
        computed: {
            valueFormatted () {
                return formatRelative(new Date(this.value), new Date(), {
                    locale: {
                        ...enGB,
                        formatRelative: token => ({
                            lastWeek: '\'Last\' eeee \'at\' p',
                            yesterday: '\'Yesterday at\' p',
                            today: '\'Today at\' p',
                            tomorrow: '\'Tomorrow at\' p',
                            nextWeek: 'eeee \'at\' p',
                            other: 'P'
                        })[token]
                    }
                })
            }
        }
    }
</script>
