<template>
    <!-- InvitationForm.vue -->
    <v-card class="mx-auto">
        <!-- Form Toolbar -->
        <base-form-toolbar
            :loading="loading"
            :title="title"
            @close="handleSecondaryFormEvent"
        ></base-form-toolbar>

        <!-- Form -->
        <base-form-container :loading="loading">
            <v-form v-model="valid" class="pa-3" @submit.prevent>
                <!-- Name -->
                <v-text-field
                    dense
                    v-model="formData.name"
                    :rules="[validationRules.generic.required]"
                    label="Name*"
                    outlined
                    @keyup.enter="handleSubmit"
                ></v-text-field>
                <!-- Guest Side -->
                <v-select
                    dense
                    v-model="formData.side"
                    :items="sideOptions"
                    :rules="[validationRules.generic.required]"
                    label="Side*"
                    outlined
                ></v-select>
                <!-- Music Requests -->
                <v-textarea
                    outlined
                    label="Music"
                    placeholder="Let us know some songs you want to hear"
                    persistent-placeholder
                    v-model="formData.music_requests"
                ></v-textarea>
                <!-- Dietary Requirements -->
                <v-textarea
                    outlined
                    label="Dietary requirements"
                    placeholder="Let us know if anyone in your party has dietary requirements"
                    persistent-placeholder
                    v-model="formData.dietary_requirements"
                ></v-textarea>
                <!-- Additional Info -->
                <v-textarea
                    outlined
                    label="Additional Info"
                    placeholder="Anything else that we should know?"
                    persistent-placeholder
                    v-model="formData.additional_information"
                ></v-textarea>
                <!-- Guests -->
                <v-data-table
                    :headers="guestTableHeaders"
                    :items="formData.guests"
                    :hide-default-footer="true"
                    :disable-pagination="true"
                >
                    <!-- Add Guest -->
                    <template v-slot:top>
                        <base-table-toolbar>
                            <template v-slot:actions>
                                <v-btn :disabled="loading" block color="success" @click="handleAddGuest">
                                    Add Guest
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </template>
                        </base-table-toolbar>
                    </template>

                    <!-- Name -->
                    <template v-slot:item.name="{item, index}">
                        <v-text-field
                            hide-details
                            dense
                            outlined
                            :value="item.name"
                            @change="val => setGuestName(index, val)"
                        ></v-text-field>
                    </template>

                    <!-- Attending -->
                    <template v-slot:item.attending="{item, index}">
                        <v-checkbox
                            :input-value="item.attending"
                            @change="toggleAttending(index)"
                        ></v-checkbox>
                    </template>

                    <!-- Delete -->
                    <template v-slot:item.actions="{index}">
                        <v-btn icon color="error" @click="handleRemoveGuest(index)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-form>
        </base-form-container>

        <!-- Form Actions -->
        <base-form-actions-container :loading="loading">
            <base-form-actions
                :loading="saving"
                :primary-button-disabled="!canSubmit"
                @close="handleSecondaryFormEvent"
                @submit="handleSubmit"
            ></base-form-actions>
        </base-form-actions-container>
    </v-card>
</template>

<script>
    import guestSides from '@/store/static/guest-sides'
    // Mixins
    import BaseFormMixin from '@/mixins/base-form.mixin'
    import BaseTableToolbar from '@/components/BaseTableToolbar'

    export default {
        name: 'InvitationForm',
        mixins: [BaseFormMixin],
        data: () => ({
            formData: {
                name: null,
                side: guestSides.XIOMI.value,
                guests: [],
                music_requests: null,
                dietary_requirements: null,
                additional_information: null
            },
            sideOptions: Object.values(guestSides),
            guestTableHeaders: [
                {text: 'Name', value: 'name'},
                {text: 'Attending', value: 'attending'},
                {text: 'Actions', value: 'actions', align: 'right'}
            ]
        }),
        components: {
            BaseTableToolbar
        },
        computed: {
            title () {
                const vm = this

                if (vm.item.id) {
                    return vm.item.name
                }

                return 'Invitation'
            }
        },
        methods: {
            handleAddGuest () {
                const vm = this

                vm.formData.guests.push({
                    name: `Guest ${vm.formData.guests.length + 1}`,
                    attending: false
                })
            },
            handleRemoveGuest (index) {
                const vm = this
                vm.formData.guests.splice(index, 1)
            },
            toggleAttending (index) {
                const vm = this
                const guest = vm.formData.guests[index]

                vm.formData.guests.splice(index, 1, {
                    ...guest,
                    attending: !guest.attending
                })
            },
            setGuestName (index, name) {
                const vm = this
                const guest = vm.formData.guests[index]

                vm.formData.guests.splice(index, 1, {
                    ...guest,
                    name
                })
            }
        }
    }
</script>
