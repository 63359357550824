<template>
    <!-- Login.vue -->
    <v-container class="fill-height" fluid>
        <v-row>
            <v-col>
                <login-form
                    :error="error"
                    :saving="loading"
                    @submit="handleLogin"
                ></login-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {mapActions} from 'vuex'
    import logger from '@/utils/logger'
    import LoginForm from '@/components/LoginForm'
    import {ADMIN} from '@/router/route-names'

    export default {
        name: 'Login',
        data: () => ({
            loading: false,
            error: undefined
        }),
        components: {
            LoginForm
        },
        methods: {
            ...mapActions('auth', ['signIn']),
            async handleLogin (data) {
                const vm = this

                try {
                    vm.error = undefined
                    vm.loading = true
                    await vm.signIn(data)
                    await vm.$router.push({
                        name: ADMIN
                    })
                } catch (e) {
                    logger.error(e)
                    vm.error = {
                        ...e,
                        message: e.message || vm.$t('unknown_error')
                    }
                } finally {
                    vm.loading = false
                }
            }
        }
    }
</script>
