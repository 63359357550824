import {isEmpty, map, object} from 'underscore'

/**
 * @mixin
 */
export default {
    props: {
        /**
         * Emit the whole action object if an action is selected.
         */
        emitActionObject: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        defaultActionHandler (action, data) {
            return this.handleActionEvent(action, data)
        },
        emitAction (action) {
            /**
             * Action event.
             * @type {object}
             */
            this.$emit('select', action)
        },
        handleActionEvent (action, data) {
            if (!action || isEmpty(action)) {
                return
            }

            if (this.emitActionObject) {
                return this.emitAction(action)
            }

            /**
             * Emit event for the specified action.
             * @event action.event
             * @type {*}
             */
            this.$emit(action.event, data)
        },
        mapActionEvents (actions, item) {
            const vm = this
            const events = map(actions, (action) => {
                return [action.event, (data) => {
                    return vm.defaultActionHandler(action, item || data)
                }]
            })

            /**
             * return object of events {event: handler}
             */
            return object(events)
        }
    }
}
