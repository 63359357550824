// Mixins
import BaseEventsMixin from '@/mixins/base-events.mixin'

/**
 * Base table events mixin.  Handles all common base table events
 * @mixin
 */
export default {
    mixins: [BaseEventsMixin],
    computed: {
        bulkActionEvents () {
            const vm = this
            return (item) => {
                return (vm.bulkActions && vm.mapActionEvents(vm.bulkActions, item)) || {}
            }
        },
        itemActionEvents () {
            const vm = this
            return (item) => {
                return (vm.itemActions && vm.mapActionEvents(vm.itemActions, item)) || {}
            }
        },
        menuActionEvents () {
            const vm = this
            return (vm.menuActions && vm.mapActionEvents(vm.menuActions)) || {}
        }
    },
    methods: {
        mapBulkAction (action) {
            return {...action, event: `bulk:${action.event}`}
        }
    }
}
