import {contains, filter, isEmpty, isEqual, pluck} from 'underscore'
// Mixins
import BaseTableEventsMixin from './base-table-events-mixin'

/**
 * Base mixin to be used in tables.  Handles common events and options.
 * @mixin
 */
export default {
    mixins: [BaseTableEventsMixin],
    data: () => ({selectedItems: [], paginationOptions: {}}),
    props: {
        /**
         * The primary key of the items
         */
        itemKey: {
            type: String,
            default: 'uuid'
        },
        /**
         * Array of items to display in table.  Keys must match header values
         */
        items: {
            type: Array,
            required: true
        },
        /**
         * Toggle the table loading state
         */
        loading: {
            type: Boolean,
            default: false
        },
        /**
         * The pagination object from the server
         */
        pagination: {
            type: Object,
            default: () => ({})
        },
        /**
         * The title of the table
         */
        title: {
            type: String,
            default: 'Results'
        }
    },
    computed: {
        hasSelectedItems () {
            return !isEmpty(this.selectedItems)
        },
        toolbarEvents () {
            const vm = this
            return {...vm.bulkActionEvents(vm.selectedItems), ...vm.menuActionEvents}
        }
    },
    methods: {
        handleTableInput (data) {
            this.selectedItems = data
            /**
             * @event input:table
             * @type {array}
             */
            this.$emit('input:table', data)
        },
        handlePaginationInput (page) {
            this.handlePaginationEvent({page})
        },
        handlePaginationPerPageInput (value) {
            this.handlePaginationEvent({per_page: value, page: 1})
        },
        handlePaginationEvent (value) {
            /**
             * @event pagination:update
             * @type {object}
             */
            this.$emit('pagination:update', value)
        }
    },
    watch: {
        items (newVal) {
            const vm = this
            const itemIds = pluck(newVal, vm.itemKey)

            // If items are no longer available filter selected items
            const filteredItems = filter(vm.selectedItems, item => {
                return contains(itemIds, item[vm.itemKey])
            })

            if (!isEqual(filteredItems, vm.selectedItems)) {
                vm.handleTableInput(filteredItems)
            }
        }
    }
}
