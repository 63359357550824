<template>
    <!-- LoginForm.vue -->
    <v-card :max-width="cardWidth" class="mx-auto" flat>
        <!-- Form -->
        <base-form-container :loading="loading">
            <v-form v-model="valid" class="pa-3 text-center" @submit.prevent>
                <img :src="logo" width="120"/>
                <!-- Email -->
                <v-text-field
                    v-model="formData.email_address"
                    :rules="[validationRules.generic.required, validationRules.email.valid]"
                    label="Email Address*"
                    outlined
                    @keyup.enter="handleSubmit"
                ></v-text-field>
                <!-- Password -->
                <v-text-field
                    v-model="formData.password"
                    :rules="[validationRules.generic.required]"
                    label="Password*"
                    outlined
                    type="password"
                    @keyup.enter="handleSubmit"
                ></v-text-field>
            </v-form>
        </base-form-container>

        <!-- Form Actions -->
        <base-form-actions-container :loading="loading">
            <template v-slot:start>
                <router-link :to="{path: '/'}">Back to home</router-link>
            </template>
            <base-form-actions
                :hide-secondary-button="true"
                :loading="saving"
                :primary-button-disabled="!canSubmit"
                primary-button-text="Login"
                @close="handleSecondaryFormEvent"
                @submit="handleSubmit"
            ></base-form-actions>
        </base-form-actions-container>
    </v-card>
</template>

<script>
    import BaseFormMixin from '@/mixins/base-form.mixin'
    import logo from '@/assets/logo.png'

    export default {
        name: 'LoginForm',
        mixins: [BaseFormMixin],
        data: () => ({
            formData: {
                email_address: null,
                password: null
            },
            logo
        }),
        computed: {
            title () {
                return 'Admin'
            }
        }
    }
</script>
