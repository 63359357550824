export const SANTI = {
    text: 'Santi',
    value: 'santi'
}

export const XIOMI = {
    text: 'Xiomi',
    value: 'xiomi'
}

export default {
    SANTI,
    XIOMI
}
